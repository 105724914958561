import { handleAxiosError } from '@/api/helpers';
import { client } from '@/client';
import { FeatureFlag } from '@/client/feature-flags/types';
import { TableNamesEnum } from '@/common/constants';
import {
  DataTable,
  DataTableActions,
  DataTableColumnsMultiselect,
  DataTableColumnType,
  DataTableToolbar,
} from '@/components/tables/crud';
import { useFeatureFlags } from '@/hooks/query/feature-flags.hooks';
import { useTable } from '@/hooks/table.hook';
import { useToast } from '@/hooks/useToast';
import { FlexContainer } from '@/ui/styled-ui';
import { AxiosError } from 'axios';
import { MenuItem } from 'primereact/menuitem';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { FeatureFlagOverviewModal } from '../modals/FeatureFlagOverviewModal';
import { FeatureFlagsModal } from '../modals/FeatureFlagsModal';

const StyledLink = styled.a`
  display: flex;
  justify-content: flex-start;
  min-width: max-content;
  text-decoration: none;
  color: var(--black-main);

  i {
    align-self: center;
  }

  &:hover {
    cursor: pointer;
    color: var(--red-dark);
  }
`;

export const FeatureFlagsDatatable: React.FC = () => {
  const { t } = useTranslation();
  const toast = useToast();

  const { apiFilters } = useTable();

  const [isFeatureModalVisible, setIsFeatureModalVisible] = useState(false);
  const [
    isFeatureActivatedAccountsModalVisible,
    setIsFeatureActivatedAccountsModalVisible,
  ] = useState(false);
  const [selectedFeature, setSelectedFeature] = useState<FeatureFlag>();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const { isLoading, flags, refetch } = useFeatureFlags({
    filters: apiFilters,
  });

  const columns: DataTableColumnType[] = [
    {
      field: 'name',
      header: t('feature.name'),
      sortable: false,
      filterable: false,
      render: (row: FeatureFlag) => <span>{t(`features.${row?.key}`)}</span>,
    },
    {
      field: 'key',
      header: t('feature.key'),
      sortable: false,
      filterable: false,
      render: (row: FeatureFlag) => <span>{row?.key}</span>,
    },
    {
      field: 'productOwner',
      header: t('feature.productOwner'),
      sortable: false,
      filterable: false,
      render: (row: FeatureFlag) =>
        row.productOwner ? (
          <span>{row?.productOwner}</span>
        ) : (
          <span>&#8212;</span>
        ),
    },
    {
      field: 'documentationUrl',
      header: t('feature.docUrl'),
      sortable: false,
      filterable: false,
      render: (row: FeatureFlag) =>
        row.documentationUrl ? (
          <StyledLink
            href={row?.documentationUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div>{t('generic.link')}</div>
            <i className="pi pi-external-link x2 ml-2" />
          </StyledLink>
        ) : (
          <span>&#8212;</span>
        ),
    },
    {
      field: 'actions',
      header: t('generic.actions'),
      sortable: false,
      filterable: false,
      style: {
        width: '80px',
        textAlign: 'center',
      },
      render: (row: FeatureFlag) => (
        <DataTableActions menuItems={menuItems(row)} />
      ),
    },
  ];

  // Set the preselected columns
  const [visibleColumns, setVisibleColumns] = useState<string[]>([]);

  const defaultVisibleColumns = columns.map((column) => column.field);
  const alwaysVisibleColumns = ['name', 'actions'];
  const toolbar = (
    <DataTableToolbar>
      <FlexContainer
        justify="space-between"
        gap={8}
        align="flex-start"
        wrap="wrap"
      >
        <div />
        <DataTableColumnsMultiselect
          columns={columns}
          tableName={TableNamesEnum.FEATURES_LIST}
          visibleColumns={visibleColumns}
          setVisibleColumns={setVisibleColumns}
          defaultVisibleColumns={defaultVisibleColumns}
          alwaysVisibleColumns={alwaysVisibleColumns}
        />
      </FlexContainer>
    </DataTableToolbar>
  );

  const menuItems = (feature: FeatureFlag) => {
    const menu: MenuItem[] = [
      {
        label: feature.isRoleBased
          ? t('feature.addAccountOrRole')
          : t('feature.addAccount'),
        icon: 'pi pi-plus',
        command: () => {
          setSelectedFeature(feature);
          setIsFeatureModalVisible(true);
        },
      },
      {
        label: t('feature.activated.accounts'),
        icon: 'pi pi-users',
        command: () => {
          setSelectedFeature(feature);
          setIsFeatureActivatedAccountsModalVisible(true);
        },
      },
    ];

    return menu;
  };

  const handleUpdateFeatureFlag = async (
    active: boolean,
    roles: string[],
    accounts?: string[],
    productOwner?: string | null,
    documentationUrl?: string | null,
  ) => {
    try {
      if (!selectedFeature) return;

      setIsSubmitting(true);

      await client.featureFlags.updateFeatureFlags(selectedFeature.id, {
        accountMappings: accounts?.length
          ? accounts.map((id: string) => ({
              id,
              roles,
              active: active,
            }))
          : [],
        productOwner,
        documentationUrl,
      });

      refetch();

      setIsFeatureModalVisible(false);

      toast?.success(t('toast.success'), t('feature.edit.success'));
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <DataTable
        data={flags?.result}
        count={flags?.count}
        isLoading={isLoading}
        columns={columns}
        visibleColumns={visibleColumns}
        toolbar={toolbar}
      />

      <FeatureFlagsModal
        currentFeature={selectedFeature}
        visible={isFeatureModalVisible}
        isSubmitting={isSubmitting}
        onSubmit={handleUpdateFeatureFlag}
        onHide={() => setIsFeatureModalVisible(false)}
      />

      <FeatureFlagOverviewModal
        currentFeature={selectedFeature}
        visible={isFeatureActivatedAccountsModalVisible}
        isSubmitting={isSubmitting}
        setIsSubmitting={setIsSubmitting}
        onHide={() => setIsFeatureActivatedAccountsModalVisible(false)}
      />
    </>
  );
};
