import { FilePurpose } from '@/api/enums';
import { equal } from '@/api/helpers';
import { Account } from '@/client/accounts';
import { Branch } from '@/client/branches';
import { CourseEntityEnrollEnum } from '@/client/courses';
import { File } from '@/client/files';
import { Group } from '@/client/groups';
import {
  SecurityCultureSurvey,
  SecurityCultureUploadSurveyFormValues,
  SurveyStatus,
} from '@/client/security-culture/types';
import {
  LoadingStateType,
  RedirectPaths,
  RedirectPathsEnum,
} from '@/common/constants';
import { DialogContext } from '@/common/context';
import { TargetEntity } from '@/common/types/targets.type';
import {
  useBranchesPartialRequest,
  useGroupsPartialRequest,
} from '@/hooks/query';
import { useAppSelector } from '@/hooks/store';
import { selectCurrentAccount } from '@/store/features/account';
import { AppFileUpload } from '@/ui/app-file-upload/AppFileUpload';
import { AppButton } from '@/ui/buttons';
import { AppCalendar } from '@/ui/calendar';
import { FormContainer } from '@/ui/styled-ui';
import {
  formatTargetEntities,
  normalizeTargetsEntitiesFromRawObject,
} from '@/utils/targets';
import { Field, Form, Formik } from 'formik';
import moment from 'moment';
import { DropdownChangeEvent } from 'primereact/dropdown';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { FormikMultiSelect, FormikSwitch } from '../form';
import { securityCultureUploadSurveySchema } from './validations';

type SecurityCultureUploadSurveyFormProps = {
  onSubmit: (data: SecurityCultureUploadSurveyFormValues) => void;
  survey?: SecurityCultureSurvey;
  state?: LoadingStateType;
  isDemoForm?: boolean;
};

export const SecurityCultureUploadSurveyForm: React.FC<
  SecurityCultureUploadSurveyFormProps
> = ({ onSubmit, survey, state, isDemoForm }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setDialogData } = useContext(DialogContext);
  const currentAccount = useAppSelector(selectCurrentAccount);

  const [initialValues, setInitialValues] =
    useState<SecurityCultureUploadSurveyFormValues>({
      id: undefined,
      file: undefined,
      date: undefined,
      targets: [],
      published: false,
    });

  const groupedEntities = useRef<
    {
      label: string;
      items: TargetEntity[];
    }[]
  >([]);

  const { branches, isLoading: isBranchesLoading } = useBranchesPartialRequest({
    accountId: currentAccount?.id,
    filters: [equal('active', true)],
    sort: ['name,asc'],
    withDescendants: true,
    take: 20,
    enabled: !isDemoForm,
  });

  const { groups, isLoading: isGroupsLoading } = useGroupsPartialRequest({
    accountId: currentAccount?.id,
    filters: [equal('active', true)],
    sort: ['name,asc'],
    enabled: !isDemoForm,
  });

  useEffect(() => {
    if (currentAccount && !isBranchesLoading && !isGroupsLoading) {
      const entities = [];

      entities.push({
        label: t('account'),
        items: [currentAccount],
      });

      if (branches?.length) {
        entities.push({
          label: t('branches'),
          items: formatTargetEntities(
            branches,
            null,
            CourseEntityEnrollEnum.BRANCHES,
          ),
        });
      }

      if (groups?.length) {
        entities.push({
          label: t('groups'),
          items: formatTargetEntities(
            groups,
            null,
            CourseEntityEnrollEnum.GROUPS,
          ),
        });
      }

      groupedEntities.current = entities;
    }

    if (survey) {
      const targets =
        survey?.targets && currentAccount
          ? normalizeTargetsEntitiesFromRawObject(
              survey?.targets,
              currentAccount,
            )
          : {};

      setInitialValues({
        id: survey.id,
        file: survey?.file,
        date: moment(survey?.date).toDate(),
        targets: Object.values(targets).flat() as TargetEntity[],
        published: survey?.status === SurveyStatus.PUBLISHED,
      });
    }
  }, [isBranchesLoading, isGroupsLoading, survey]);

  const handleFileUpload = (
    file: File | null,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean,
    ) => void,
  ) => {
    if (file?.id) {
      setFieldValue('file', file);
    } else {
      setFieldValue('file', null);
    }
  };

  const cancelHandler = (values: SecurityCultureUploadSurveyFormValues) => {
    if (initialValues !== values) {
      setDialogData({
        show: true,
        type: 'confirmation',
        header: t('security.culture.cancel.upload'),
        message: t('course.cancel.confirm'),
        onAccept: () =>
          navigate(RedirectPaths[RedirectPathsEnum.SECURITY_CULTURE]()),
      });
    } else {
      navigate(RedirectPaths[RedirectPathsEnum.SECURITY_CULTURE]());
    }
  };

  const checkIsOptionDisabled = (
    targets: (Account | Branch | Group)[],
    option: Account | Branch | Group,
  ) => {
    if (
      targets.find(
        (entity: Account | Branch | Group) => entity.id === currentAccount?.id,
      ) &&
      option.id !== currentAccount?.id
    )
      return true;
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={securityCultureUploadSurveySchema(t, isDemoForm)}
      validateOnMount
      onSubmit={(data) => onSubmit(data)}
    >
      {({ errors, values, setFieldValue, validateForm }) => {
        return (
          <FormContainer>
            <Form className="w-full">
              <div className="w-full">
                <label htmlFor="file">
                  {t('security.culture.survey.file')}
                  {isDemoForm && <span className="red"> *</span>}
                </label>
                <AppFileUpload
                  id="sc-file"
                  preselectedFile={values.file as File}
                  accept="text/csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  purpose={FilePurpose.SURVEY}
                  onFileUpload={(file) => {
                    handleFileUpload(file, setFieldValue);
                  }}
                />
                {!!errors.file && (
                  <small className="p-error">{errors.file}</small>
                )}
              </div>
              {!isDemoForm && !survey?.isDemo && (
                <>
                  <div className="field w-full mt-4 mb-4">
                    <label htmlFor="date">
                      {t('generic.date')}
                      <span className="red"> *</span>
                    </label>
                    <AppCalendar
                      id="date"
                      name="date"
                      view="month"
                      dateFormat="MM yy"
                      className="w-full"
                      placeholder={t('generic.date')}
                      maxDate={new Date()}
                      value={values.date}
                      onChange={(event) => {
                        setFieldValue(
                          'date',
                          moment(event.value?.toString()).utc(true).toDate(),
                        );
                      }}
                      readOnlyInput
                    />
                  </div>
                  <div className="field w-full">
                    <Field
                      id="targets"
                      name="targets"
                      dropdownIcon={
                        isBranchesLoading || isGroupsLoading
                          ? 'pi pi-spinner pi-spin'
                          : 'pi pi-chevron-down'
                      }
                      filter
                      label={t('generic.recipients')}
                      className="w-full"
                      display="chip"
                      placeholder={t('generic.select')}
                      required
                      optionDisabled={(option: Account | Branch | Group) =>
                        checkIsOptionDisabled(values.targets || [], option)
                      }
                      onChange={(event: DropdownChangeEvent) => {
                        setFieldValue('targets', event.value);
                      }}
                      dataKey="id"
                      optionLabel="name"
                      optionGroupLabel="label"
                      optionGroupChildren="items"
                      component={FormikMultiSelect}
                      options={groupedEntities.current}
                      disabled={isBranchesLoading || isGroupsLoading}
                    />
                  </div>
                  <div className="field-checkbox  mt-5">
                    <Field
                      inputId="published"
                      name="published"
                      prefix={t('generic.draft')}
                      label={t('generic.published')}
                      onChange={async (
                        e: React.ChangeEvent<HTMLInputElement>,
                      ) => {
                        setFieldValue('published', e.target.value);
                        setTimeout(async () => {
                          await validateForm();
                        }, 0);
                      }}
                      component={FormikSwitch}
                    />
                  </div>
                </>
              )}
              <div className={isDemoForm ? 'mt-4 mb-4' : 'mb-4'}>
                <AppButton
                  severity="secondary"
                  type="outlined"
                  label={t('button.cancel')}
                  className="mt-3 mr-3"
                  state={state}
                  onClick={() => cancelHandler(values)}
                />
                <AppButton
                  isSubmit
                  severity="success"
                  label={survey?.id ? t('generic.edit') : t('button.create')}
                  className="mt-3"
                  state={state}
                  isDisabled={!!Object.keys(errors).length}
                />
              </div>
            </Form>
          </FormContainer>
        );
      }}
    </Formik>
  );
};
