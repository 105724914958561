import { TranslationFunctionType } from '@/common/types';
import { array, boolean, object, string } from 'yup';

export const featureFlagsValidationSchema = (
  t: TranslationFunctionType,
  isAccount: boolean,
) =>
  object().shape({
    isRoleBased: boolean(),
    featureAccounts: array(),
    featureRoles: array().when(
      ['isRoleBased', 'featureAccounts'],
      ([isRoleBased, featureAccounts], schema) =>
        isRoleBased && (featureAccounts.length || isAccount)
          ? schema.min(1, t('error.requiredField'))
          : schema,
    ),
    productOwner: string().nullable(),
    documentationUrl: string().url(t('error.invalidUrl')).nullable(),
  });
