import { handleAxiosError } from '@/api/helpers';
import { AppBreadCrumbTemplate } from '@/app/AppBreadCrumbTemplate';
import { client } from '@/client';
import { Account } from '@/client/accounts';
import { Campaign, CampaignStatusesEnum } from '@/client/campaigns';
import { Subjects } from '@/client/users';
import {
  LoadingStateType,
  LoadingStatuses,
  RedirectPaths,
  RedirectPathsEnum,
} from '@/common/constants';
import { TranslationFunctionType } from '@/common/types';
import { CampaignReportsDatatable } from '@/components/campaigns/datatables';
import { CampaignsReports } from '@/components/dashboards/admin';
import { useNotifications } from '@/hooks/notifications.hook';
import { useCampaign } from '@/hooks/query';
import { useAppSelector } from '@/hooks/store';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { useMixpanel } from '@/hooks/useMixpanel';
import { pendoEvent, pendoProperty, usePendo } from '@/hooks/usePendo';
import { useToast } from '@/hooks/useToast';
import { LoadingPage } from '@/pages';
import { selectCurrentAccount } from '@/store/features/account';
import { AppBreadCrumb } from '@/ui/breadcrumb';
import { AppButton } from '@/ui/buttons';
import { FlexContainer } from '@/ui/styled-ui';
import { AxiosError } from 'axios';
import mixpanel from 'mixpanel-browser';
import { MenuItem } from 'primereact/menuitem';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

const StyledLegendContainer = styled(FlexContainer)`
  margin-top: var(--default-padding);

  > div {
    display: flex;
    gap: var(--xxsmall-padding);
    font-size: var(--small-font-size);
    user-select: none;
  }
`;

const StyledDot = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;

  &.red {
    background-color: var(--red-main);
  }

  &.red-light {
    background-color: var(--red-light);
  }
`;

const getBreadcrumbs = (
  account: Account,
  campaign: Campaign,
  t: TranslationFunctionType,
): MenuItem[] => {
  return [
    {
      label: account?.name,
      url: !account?.isSystem
        ? RedirectPaths[RedirectPathsEnum.ACCOUNT](account?.id)
        : RedirectPaths[RedirectPathsEnum.EDIT_ACCOUNT](account?.id),
      template: AppBreadCrumbTemplate,
    },
    {
      label: t('campaigns'),
      url: RedirectPaths[RedirectPathsEnum.CAMPAIGNS](),
      template: AppBreadCrumbTemplate,
    },
    {
      label: t('generic.campaign.report'),
      url: RedirectPaths[RedirectPathsEnum.CAMPAIGNS_REPORT](campaign?.id),
      template: AppBreadCrumbTemplate,
    },
  ];
};

export const CampaignsReportPage: React.FC = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const toast = useToast();
  const { canUseFeature } = useFeatureFlag();
  const currentAccount = useAppSelector(selectCurrentAccount);
  const [syncState, setSyncState] = useState<LoadingStateType>(
    LoadingStatuses.IDLE,
  );
  const { pendoTrack } = usePendo();
  const { track } = useMixpanel();
  const { isLoading, campaign, refetch } = useCampaign({
    campaignId: id,
  });

  useEffect(() => {
    if (canUseFeature(Subjects.EVENT_TRACKING) && (mixpanel as any)?.__loaded) {
      track('View Phishing reports');

      pendoTrack(pendoEvent.campaignsReportVisited, {
        [pendoProperty.forAccountId]: currentAccount?.id ?? '',
        [pendoProperty.forAccountName]: currentAccount?.name ?? '',
        [pendoProperty.campaignId]: campaign?.id ?? '',
        [pendoProperty.campaignName]: campaign?.name ?? '',
      });
    }
  }, [(mixpanel as any)?.__loaded]);

  const [shouldRefetchUsers, setShouldRefetchUsers] = useState(false);

  const toggleShouldRefetchUsers = () =>
    setShouldRefetchUsers((state) => !state);

  const {
    lastMessage,
    setNotificationParam: setCampaignId,
    notificationParam,
    isConnected,
  } = useNotifications(client.campaigns.campaignsNotifyUrl);

  useEffect(() => {
    if (!notificationParam) return;
    handleNotifyCampaignRefresh(lastMessage);
  }, [JSON.stringify(lastMessage)]);

  const handleNotifyCampaignRefresh = async (messages: any) => {
    if (
      !messages?.data?.event ||
      ![
        'campaign.results.sync.finished',
        'campaign.results.sync.failed',
      ].includes(messages?.data?.event)
    )
      return;

    if (messages?.data?.event === 'campaign.results.sync.finished') {
      toast?.success(t('toast.success'), t('campaign.report.refresh.success'));
      await refetch();
      toggleShouldRefetchUsers();
    }

    if (messages?.data?.event === 'campaign.results.sync.failed') {
      toast?.error(t('toast.error'), t('campaign.report.refresh.error'), 5000);
    }

    setCampaignId(undefined);
    setSyncState(LoadingStatuses.IDLE);
  };

  const handleRefreshReports = async () => {
    try {
      setCampaignId(campaign?.id);
      // Wait for a notify connection to establish
      await new Promise((resolve) => {
        const intervalId = setInterval(() => {
          if (isConnected.current) {
            clearInterval(intervalId);
            resolve(true);
          }
        }, 100);
      });

      setSyncState(LoadingStatuses.LOADING);
      await client.campaigns.syncCampaignResults(campaign?.id as string);
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
    }
  };

  if (!campaign || isLoading) {
    return <LoadingPage message={t('camapign.report.load')} />;
  }

  return (
    <>
      <AppBreadCrumb
        model={getBreadcrumbs(currentAccount as Account, campaign, t)}
      />
      <FlexContainer justify="space-between">
        <h1 className="m-0">
          {campaign?.name} - {t('generic.campaign.report')}
        </h1>
        {campaign?.status &&
          [
            CampaignStatusesEnum.ACTIVE,
            CampaignStatusesEnum.SCHEDULED,
          ].includes(campaign.status) && (
            <AppButton
              label={t('campaign.report.refresh')}
              type="outlined"
              onClick={handleRefreshReports}
              state={syncState}
            />
          )}
      </FlexContainer>

      {canUseFeature(Subjects.PHISHING_BENCHMARK) &&
        !!campaign?.benchmarkSummary && (
          <StyledLegendContainer justify="flex-end" gap={18}>
            <div>
              <StyledDot className="red" />
              {t('campaign.benchmark.your.campaign')}
            </div>
            <div>
              <StyledDot className="red-light" />
              {t('campaign.benchmark.other.organisations')}
            </div>
          </StyledLegendContainer>
        )}

      <CampaignsReports
        campaign={campaign}
        campaignsSummary={campaign.summary}
        isLoading={isLoading}
        isBenchmark={
          canUseFeature(Subjects.PHISHING_BENCHMARK) &&
          !!campaign?.benchmarkSummary
        }
        t={t}
        className="my-4"
      />

      <CampaignReportsDatatable
        onShouldRefetchChange={toggleShouldRefetchUsers}
        shouldRefetch={shouldRefetchUsers}
        campaign={campaign}
        withToolbar
      />
    </>
  );
};
