import { Account } from '@/client/accounts';
import { Branch } from '@/client/branches';
import {
  Course,
  CourseAccount,
  CourseBranch,
  CourseEntity,
  CourseUser,
} from '@/client/courses';
import { Group } from '@/client/groups';
import { User } from '@/client/users';
import { selectCurrentUser } from '@/store/features/users';
import { DateFormats } from '@/system-settings/enums';
import moment from 'moment';
import { Md5 } from 'ts-md5';
import { useAppSelector } from './store';

const env_check = !!process.env.REACT_APP_PENDO_KEY;

type PendoTrackEnrollmentProps = {
  eventType: pendoEvent;
  type: 'create' | 'update' | 'delete';
  courses: Course[];
  accounts: Account[] | CourseAccount[] | CourseEntity[];
  branches?: Branch[] | CourseBranch[] | CourseEntity[];
  groups?: Group[] | CourseBranch[] | CourseEntity[];
  users?: User[] | CourseUser[] | CourseEntity[];
  date?: Date | null;
  oldEnrollDate?: Date | null;
  autoEnroll?: boolean;
};

export const enum pendoEvent {
  awarenessReportVisited = 'Awareness report visited',
  summaryReportVisited = 'Summary report visited',
  campaignsReportVisited = 'Campaigns report visited',
  awarenessReportExported = 'Awareness report exported',
  summaryReportExported = 'Summary report exported',
  campaignsReportExported = 'Campaigns report exported',
  enrollAccount = 'Enroll account',
  enrollBranch = 'Enroll branch',
  enrollGroup = 'Enroll group',
  enrollUser = 'Enroll user',
  courseCompleted = 'Course completed',
}

export const enum pendoProperty {
  id = 'id',
  name = 'name',
  account = 'account',
  branch = 'branch',
  user = 'user',
  userAccount = 'user_account',
  score = 'score',
  type = 'type',
  create = 'create',
  delete = 'delete',
  created = 'created',
  forAccountId = 'report_account_id',
  forAccountName = 'report_account_name',
  campaignId = 'campaign_id',
  campaignName = 'campaign_name',
  courseName = 'course_name',
  courseId = 'course_id',
  scheduleDate = 'schedule_date_utc',
  totalDays = 'total_days',
  oldEnrollDate = 'old_enroll_date_utc',
  userRole = 'user_role',
  userAccountType = 'user_account_type', // free or regular
  freeTrialEndDate = 'free_trial_end_date',
  phishingOnly = 'phising_only',
  adSyncEnabled = 'ad_sync_enabled',
  ssoEnabled = 'sso_enabled',
  courseEnrollType = 'course_enroll_type',
  enrolledTo = 'enrolled_to',
}

export const usePendo = () => {
  const currentUser = useAppSelector(selectCurrentUser);

  const formatUser = (user: User) => {
    return {
      visitor: {
        [pendoProperty.id]: Md5.hashStr(user.id),
        [pendoProperty.created]: moment(user.created).format(
          DateFormats.TIMEDATE,
        ),
        [pendoProperty.userRole]: user.role.name,
      },
      account: {
        [pendoProperty.id]: user?.account?.id,
        [pendoProperty.name]: user?.account?.name,
      },
    };
  };

  const formatUserAdditionalProperties = {
    [pendoProperty.userRole]: currentUser?.role.name,
    [pendoProperty.userAccountType]: currentUser?.account.isFreeTrial
      ? 'Free trial'
      : currentUser?.account?.blockSystemEmails
      ? 'Phishing only'
      : 'AWT',
    [pendoProperty.freeTrialEndDate]: currentUser?.account.isFreeTrial,
    [pendoProperty.adSyncEnabled]:
      currentUser?.account?.activeDirectoryIntegration?.active,
    [pendoProperty.ssoEnabled]: !!currentUser?.account?.meta?.isSSO,
  };

  const pendoInitialize = (user?: User) => {
    if (env_check && typeof pendo !== 'undefined') {
      const userFormatted = user ? formatUser(user) : {};
      pendo.initialize(userFormatted);
    }
  };

  const pendoIdentify = (user: User) => {
    if (env_check && typeof pendo !== 'undefined') {
      pendo.identify(formatUser(user));
    }
  };

  const pendoTrack = (name: pendoEvent, props?: any) => {
    if (env_check && typeof pendo !== 'undefined') {
      pendo.track(name, { ...props, ...formatUserAdditionalProperties });
    }
  };

  const pendoTrackEnrollment = (props: PendoTrackEnrollmentProps) => {
    const {
      eventType,
      type,
      courses,
      accounts,
      branches,
      groups,
      users,
      date,
      oldEnrollDate,
      autoEnroll,
    } = props;

    const formatDateProperties = (date?: Date | null) => {
      if (date) {
        const scheduleDate = moment(date).utc(true);
        const currentDate = moment().utc(true).hours(0);

        return {
          [pendoProperty.scheduleDate]: moment(scheduleDate).format(
            DateFormats.DATEONLY,
          ),
          [pendoProperty.totalDays]: scheduleDate.diff(currentDate, 'days'),
        };
      }

      return {};
    };

    for (const course of courses) {
      const dateProperties = formatDateProperties(date);
      const oldEnrollDateProperty = oldEnrollDate
        ? {
            [pendoProperty.oldEnrollDate]: moment(oldEnrollDate)
              .utc(true)
              .format(DateFormats.DATEONLY),
          }
        : {};

      const allProperties = {
        [pendoProperty.courseName]: course.name,
        [pendoProperty.type]: type,
        ...dateProperties,
        ...oldEnrollDateProperty,
        ...formatUserAdditionalProperties,
      };

      switch (eventType) {
        case pendoEvent.enrollAccount: {
          for (const account of accounts) {
            pendoTrack(eventType, {
              ...allProperties,
              [pendoProperty.courseEnrollType]: autoEnroll ? 'auto' : 'manual',
              [pendoProperty.enrolledTo]: account.name,
            });
          }
          break;
        }
        case pendoEvent.enrollBranch: {
          for (const branch of branches ?? []) {
            pendoTrack(eventType, {
              ...allProperties,
              [pendoProperty.account]:
                accounts.length === 1 ? accounts[0].name : null,
              [pendoProperty.courseEnrollType]: autoEnroll ? 'auto' : 'manual',
              [pendoProperty.enrolledTo]: branch.name,
            });
          }
          break;
        }
        case pendoEvent.enrollGroup: {
          for (const group of groups ?? []) {
            pendoTrack(eventType, {
              ...allProperties,
              [pendoProperty.account]:
                accounts.length === 1 ? accounts[0].name : null,
              [pendoProperty.enrolledTo]: group.name,
            });
          }
          break;
        }
        case pendoEvent.enrollUser: {
          for (const user of users ?? []) {
            pendoTrack(eventType, {
              ...allProperties,
              [pendoProperty.account]:
                accounts.length === 1 ? accounts[0].name : null,
              [pendoProperty.enrolledTo]: Md5.hashStr(user.id),
            });
          }
          break;
        }
      }
    }
  };

  return { pendoInitialize, pendoIdentify, pendoTrack, pendoTrackEnrollment };
};
