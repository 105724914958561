import { equal } from '@/api/helpers';
import { client } from '@/client';
import { Account } from '@/client/accounts';
import {
  AccountFeatureFlag,
  FeatureFlag,
  FeatureFlagDetails,
} from '@/client/feature-flags/types';
import { LoadingStatuses } from '@/common/constants';
import { allRolesOptions } from '@/common/constants/roles';
import { FormikInput, FormikMultiSelect } from '@/components/form';
import { AccountsMultiselectInput } from '@/components/form/selectors';
import { useRoles } from '@/hooks/query';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import {
  selectCurrentAccount,
  setCurrentAccount,
} from '@/store/features/account';
import { setCurrentUser } from '@/store/features/users';
import { AppButton } from '@/ui/buttons';
import { FlexContainer } from '@/ui/styled-ui';
import { Field, Form, Formik } from 'formik';
import { Dialog, DialogProps } from 'primereact/dialog';
import { DropdownChangeEvent } from 'primereact/dropdown';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { featureFlagsValidationSchema } from '../validations';

const StyledDialog = styled(Dialog)`
  width: 500px;
`;

type FeatureFlagsModalProps = {
  currentFeature?: AccountFeatureFlag | FeatureFlag | null;
  isSubmitting?: boolean;
  isAccount?: boolean;
  onSubmit: (
    active: boolean,
    roles: string[],
    accounts?: string[],
    productOwner?: string | null,
    documentationUrl?: string | null,
  ) => void;
} & DialogProps;

export const FeatureFlagsModal: React.FC<FeatureFlagsModalProps> = ({
  currentFeature,
  isSubmitting,
  isAccount,
  onSubmit,
  onHide,
  visible,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const currentAccount = useAppSelector(selectCurrentAccount);

  const [initialValues, setInitialValues] = useState<FeatureFlagDetails>({
    isRoleBased: false,
    active: false,
    featureRoles: [],
    featureAccounts: [],
    productOwner: undefined,
    documentationUrl: undefined,
  });

  const { roles } = useRoles({
    take: 20,
  });

  useEffect(() => {
    if (isAccount) {
      const accountFeatureRoles =
        (currentFeature as AccountFeatureFlag)?.roles?.map(
          ({ code }) => code,
        ) || [];
      setInitialValues((state) => ({
        ...state,
        isRoleBased: !!(currentFeature as AccountFeatureFlag)?.featureFlag
          ?.isRoleBased,
        featureRoles: accountFeatureRoles,
        active: !!accountFeatureRoles.length,
      }));
    } else {
      setInitialValues((state) => ({
        ...state,
        isRoleBased: !!(currentFeature as FeatureFlag)?.isRoleBased,
        productOwner:
          (currentFeature as FeatureFlag)?.productOwner ?? undefined,
        documentationUrl:
          (currentFeature as FeatureFlag)?.documentationUrl ?? undefined,
      }));
    }
  }, [currentFeature]);

  const handleEditFeatureFlag = async (data: FeatureFlagDetails) => {
    const rolesIds = data.featureRoles
      .map((role) => roles?.result.find(({ code }) => code === role)?.id || '')
      .filter(Boolean);
    const accountsIds = data.featureAccounts.map(({ id }) => id);

    await onSubmit(
      data.active,
      rolesIds,
      accountsIds,
      data.productOwner?.length ? data.productOwner : null,
      data.documentationUrl?.length ? data.documentationUrl : null,
    );

    // Refetch permissions
    const response = await client.accounts.getAccount(currentAccount?.id);
    dispatch(setCurrentAccount(response));
    const user = await client.default.getMe();
    dispatch(setCurrentUser(user));
  };

  return (
    <StyledDialog
      blockScroll
      visible={visible}
      header={
        <h1>
          {t(
            `features.${
              isAccount
                ? (currentFeature as AccountFeatureFlag)?.featureFlag?.key
                : (currentFeature as FeatureFlag)?.key
            }`,
          )}
        </h1>
      }
      onHide={onHide}
      draggable={false}
      data-testid="features-modal"
    >
      <Formik
        initialValues={initialValues}
        validationSchema={featureFlagsValidationSchema(t, !!isAccount)}
        validateOnMount
        onSubmit={handleEditFeatureFlag}
      >
        {({ values, setFieldValue, errors, validateForm }) => {
          return (
            <Form>
              <FlexContainer justify="flex-start" direction="column">
                {!isAccount && (
                  <div className="flex flex-column field w-full">
                    <label>{t('accounts')}</label>
                    <AccountsMultiselectInput
                      className="w-full"
                      selectedOptions={values.featureAccounts}
                      additionalFilters={[equal('active', true)]}
                      onChange={(e) => {
                        setFieldValue('featureAccounts', e as Account[], true);
                        setFieldValue(
                          'active',
                          !values.isRoleBased && !!e.length,
                        );
                      }}
                    />
                  </div>
                )}
                {((isAccount && values.isRoleBased) ||
                  (values.isRoleBased &&
                    !!values.featureAccounts.length &&
                    !isAccount)) && (
                  <div className="field w-full">
                    <Field
                      id="feature-roles"
                      name="featureRoles"
                      label={t('generic.role')}
                      className="w-full"
                      display="chip"
                      placeholder={t('roles.select')}
                      required
                      onChange={(e: DropdownChangeEvent) => {
                        setFieldValue('featureRoles', e.value);
                        setFieldValue('active', !!e.value.length);
                        setTimeout(async () => {
                          await validateForm();
                        }, 0);
                      }}
                      component={FormikMultiSelect}
                      options={allRolesOptions(t)}
                      optionLabel="label"
                      dataKey="id"
                    />
                  </div>
                )}
                {!isAccount && (
                  <>
                    <div className="field w-full">
                      <Field
                        id="product-owner"
                        name="productOwner"
                        label={t('feature.productOwner')}
                        className="w-full"
                        component={FormikInput}
                        placeholder={t('feature.productOwner')}
                      />
                    </div>
                    <div className="field w-full">
                      <Field
                        id="documentation-URL"
                        name="documentationUrl"
                        label={t('feature.docUrl')}
                        className="w-full"
                        component={FormikInput}
                        placeholder={t('feature.docUrl')}
                      />
                    </div>
                  </>
                )}
              </FlexContainer>

              <FlexContainer justify="flex-end" className="mt-2">
                <AppButton
                  label={t('button.cancel')}
                  severity="secondary"
                  type="outlined"
                  onClick={onHide}
                  className="mr-3"
                  isDisabled={isSubmitting}
                />
                <AppButton
                  label={t('button.save')}
                  state={
                    isSubmitting
                      ? LoadingStatuses.LOADING
                      : LoadingStatuses.IDLE
                  }
                  isSubmit
                  isDisabled={isSubmitting || !!Object.keys(errors).length}
                />
              </FlexContainer>
            </Form>
          );
        }}
      </Formik>
    </StyledDialog>
  );
};
